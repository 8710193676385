<template>
    <div>
        <b-card-code title="Add New Sensor" :before-change="validationForm">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <!-- active -->
                        <b-col md="6">
                            <validation-provider #default="{ errors }" name="Active" rules="required">
                                <b-form-group label="Active" label-for="active" :state="errors.length > 0 ? false:null">
                                    <v-select id="active" v-model="activeSelect" :state="activeSelect === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="activeOptions" :selectable="option => ! option.value.includes('select_value')" label="text" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- type -->
                        <b-col md="6">
                            <validation-provider #default="{ errors }" name="Type" rules="required">
                                <b-form-group label="Type" label-for="type" :state="errors.length > 0 ? false:null">
                                    <v-select id="type" v-model="typeSelect" :state="typeSelect === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="typeOptions" :selectable="option => ! option.value.includes('select_value')" label="text" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- OS type -->
                        <b-col md="6">
                            <validation-provider #default="{ errors }" name="OS Type" rules="required">
                                <b-form-group label="OS Type" label-for="OS Type" :state="errors.length > 0 ? false:null">
                                    <v-select id="os-type" v-model="osTypeSelect" :state="osTypeSelect === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="OSTypeOptions" :selectable="option => ! option.value.includes('select_value')" label="text" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- is Score -->
                        <b-col md="6">
                            <validation-provider #default="{ errors }" name="Is Score" rules="required">
                                <b-form-group label="Is Score" label-for="is score" :state="errors.length > 0 ? false:null">
                                    <v-select id="is-score" v-model="isScoreSelect" :state="isScoreSelect === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="isScoreOptions" :selectable="option => ! option.value.includes('select_value')" label="text" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Score -->
                        <b-col md="6" v-if="isScoreSelect != null && isScoreSelect.value == '1'">
                            <b-form-group label="Score" label-for="Score">
                                <validation-provider #default="{ errors }" name="Score" rules="required|regex:^[0]*[1-9][0-9]*$">
                                    <b-form-input id="Score" min="1" type="number" v-model="data.score" :state="errors.length > 0 ? false:null" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <hr>
                        </b-col>
                        <b-col sm="12">
                            <div>
                                <!-- Row Loop -->
                                <b-row v-for="(item, index) in tranLocaleSelect"  :key="index">
                                    <!-- translation language -->
                                    <b-col md="6">
                                        <validation-provider #default="{ errors }" name="language" rules="required">
                                            <b-form-group label="Language" label-for="language" :state="errors.length > 0 ? false:null">
                                                <v-select :id="'language' + index" v-model="tranLocaleSelect[index]"  :state="tranLocaleSelect[index] === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="localeOptions" :selectable="option => ! option.value.includes('select_value')"
                                                    label="text" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <!-- Name -->
                                    <b-col md="6">
                                        <b-form-group  label="Name" label-for="name">
                                            <validation-provider #default="{ errors }" name="name" rules="required">
                                                <b-form-input :id="'name' + index" v-model="data.translations[index].name" :state="errors.length > 0 ? false:null" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <!-- Description -->
                                    <b-col md="12">
                                        <b-form-group  label="Description" label-for="description">
                                            <validation-provider #default="{ errors }" name="description" rules="required">
                                                <vue-editor :id="'description' + index" v-model="data.translations[index].description" :state="errors.length > 0 ? false:null" rows="2" ></vue-editor>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <!-- Remove Button -->
                                    <b-col lg="2" md="3" class="mb-50">
                                        <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" v-show="!tranLocaleSelect[index].disa" variant="outline-danger" class="mt-0 mt-md-2" @click="removeItem(index)">
                                            <feather-icon icon="XIcon" class="mr-25" />
                                            <span>Remove</span>
                                        </b-button>
                                    </b-col>
                                    <b-col cols="12">
                                        <hr>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <!-- add new button -->
                        <b-col sm="2">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block @click="repeateAgain">
                                <feather-icon icon="PlusIcon" class="mr-25" />
                                <span>Add New</span>
                            </b-button>
                        </b-col>
                        <b-col cols="12">
                            <hr>
                        </b-col>
                        <!-- submit button -->
                        <b-col>
                            <b-button size="lg" variant="primary" type="submit" @click.prevent="validationForm">
                                Submit
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card-code>
        <!-- error handelr -->
        <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false" variant="danger">
            <h4 class="alert-heading">
                Alert
            </h4>
            <div class="alert-body">
                <ul v-for="(values , index) in errors_back" :key="index">
                    <li v-for="(value , indexVal) in values" :key="indexVal">{{value}}</li>
                </ul>
            </div>
        </b-alert>
    </div>
</template>

<script>

import { VueEditor } from 'vue2-editor'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    VueEditor,
  },

  mixins: [heightTransition],
  data() {
    return {
      activeSelect: null,
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '0',
          text: 'False',
        },
        {
          value: '1',
          text: 'True',
        },
      ],
      isScoreSelect: null,
      isScoreOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '0',
          text: 'False',
        },
        {
          value: '1',
          text: 'True',
        },
      ],
      typeSelect: null,
      typeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'string',
          text: 'String',
        },
        {
          value: 'percentage ',
          text: 'Percentage ',
        },
        {
          value: 'fixed',
          text: 'Fixed',
        },
      ],
      OSTypeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'ios',
          text: 'iOS',
        },
        {
          value: 'android',
          text: 'Android',
        },
        {
          value: 'all',
          text: 'All',
        },
      ],
      osTypeSelect: null,
      data: {
        active: '',
        type: '',
        os_type: '',
        is_score: '',
        score: 1,
        translations: [
          {
            locale: '',
            name: '',
            description: '',
          },
          {
            locale: '',
            name: '',
            description: '',
          },
        ],
      },
      tranLocaleSelect: [
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'en',
          text: 'English',
          disa: true,
        },
      ],
      localeOptions: [
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      errors_back: [],
      showDismissibleAlert: false,
      required,
    }
  },
  methods: {
    // submit Brand API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.active = this.activeSelect.value
          this.data.type = this.typeSelect.value
          this.data.is_score = this.isScoreSelect.value
          this.data.os_type = this.osTypeSelect.value
          for (let trn in this.data.translations) {
            this.data.translations[trn].locale =
              this.tranLocaleSelect[trn].value
          }
          var sendData = this.data
          if (this.isScoreSelect.value != '1') {
            sendData.score = '0'
          }
          axios
            .post('sensors', sendData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push({
                path: '/sensors',
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // REPETED FORM
    repeateAgain() {
      this.data.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.tranLocaleSelect.push({})
    },
    // remove function to delete single translation from category
    removeItem(index) {
      if (this.data.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.data.translations.splice(index, 1)
        this.tranLocaleSelect.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    // basic
  },
}
</script>
 
<style lang="scss">
 
</style>


<style lang="scss" scoped>
</style>